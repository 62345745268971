import { createI18n } from 'vue-i18n'
import globalMessage from '@/locales/fr/global/globalMessage'

// Initialize messages object
const messages = {
  fr: {
    global: globalMessage
  }
}

const i18n = createI18n({
  locale: 'fr',
  messages: {
    ...messages
  }
})

export default i18n
